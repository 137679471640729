import React, { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import ClientsTable from "../components/Mainpage/ClientsTable";
import TopMenu from "../components/Mainpage/TopMenu";

export default function Main() {
  return (
    <>
      <PageHeader title="Сервіс для перевірки бекапів"></PageHeader>
      <ClientsTable></ClientsTable>
    </>
  );
}
