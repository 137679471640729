import React from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import CreateForm from "../components/Clients/Create/CreateForm";

export default function CreateClient() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate(-1)}
        title="Клієнти"
        subTitle="Створення"
      />
      <CreateForm></CreateForm>
    </>
  );
}
