import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useAuth } from "../hook/useAuth";
import TopMenu from "./Mainpage/TopMenu";

// додати користувача для перевірки оновлення

export default function Layout() {
  const { user } = useAuth();

  return (
    <>
      <TopMenu></TopMenu>
      <Outlet></Outlet>
    </>
  );
}
