import { getServer } from "../../utils/Environment";

const server = getServer();
export async function login(username, password) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ username, password, action: "login" }),
  });
  return await responce.json();
}
