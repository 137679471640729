import logo from "./logo.svg";
import "./App.css";
import AppRouter from "./router/AppRouter";
import { AuthProvider } from "./hoc/AuthProvider";
import Main from "./pages/Main";

function App() {
  return (
    <AuthProvider>
      <AppRouter>
        {/* <div className="App">
          <Main></Main>
        </div> */}
      </AppRouter>
    </AuthProvider>
  );
}

export default App;
