import React, { useState } from "react";
import { Menu } from "antd";
import {
  UserAddOutlined,
  UserOutlined,
  UserDeleteOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  getBackUps,
  getClients,
  getCompanies,
  getUsersByCompany,
} from "../../api/Clients/clients";

export default function TopMenu(props) {
  const navigate = useNavigate();

  const items = [
    {
      label: "Головна",
      key: "main",
      icon: <HomeOutlined />,
      onClick: () => navigate("/"),
    },
    {
      label: "Клієнти",
      key: "clients",
      icon: <UserOutlined />,
      children: [
        {
          label: "Додати",
          key: "addClient",
          icon: <UserAddOutlined />,
          onClick: () => navigate("create-client"),
        },
        {
          label: "Видалити",
          key: "deleteClient",
          icon: <UserDeleteOutlined />,
        },
      ],
    },
    {
      label: "Розсилка",
      key: "telegram",
      icon: <HomeOutlined />,
      onClick: () => checkBackupsAndTelegram(),
    },
  ];

  const [current, setCurrent] = useState("mail");
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  async function checkBackupsAndTelegram() {
    const companies = await getCompanies();
    // const clients = await Promise.all(
    companies.map(async (company) => {
      const clientsOfCompany = await getUsersByCompany(company);
      const backups = await getBackUps(clientsOfCompany, true);
    });
  }

  return (
    <>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
      />
    </>
  );
}
