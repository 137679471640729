import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { createClient } from "../../../api/Clients/clients";

export default function CreateForm() {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const saveResult = await createClient(values);
    if (saveResult.status === "error") {
      return message.error(saveResult.message);
    }
    message.success(saveResult.message);
    Modal.confirm({
      title: "Перейти на головну?",
      okText: "Так",
      cancelText: "Ні",
      onOk: () => navigate("/"),
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const initialValues = {
    telegramChatId: 913432164,
    pathToFolder: "",
  };

  return (
    <>
      <Row>
        <Col span={18} offset={2}>
          <Form
            name="basic"
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Компанія"
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Please input companyName!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Клієнт"
              name="clientName"
              rules={[
                {
                  required: true,
                  message: "Please input username!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="FTP логін"
              name="ftpLogin"
              rules={[
                {
                  required: true,
                  message: "Please input username!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="FTP пароль"
              name="ftpPassword"
              rules={[
                {
                  required: true,
                  message: "Please input ftp password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="К-ть бекапів"
              name="bkpQty"
              rules={[
                {
                  //   type: "integer",
                  required: true,
                  message: "Перевірте правильність введення!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="ІП адреса сервера"
              name="ipAddress"
              rules={[
                {
                  required: true,
                  message: "Please input ipAddress!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Порт для підключення"
              name="ftpPort"
              rules={[
                {
                  required: true,
                  message: "Please input ftpPort!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Ід телеграм чату для розсилки"
              name="telegramChatId"
              rules={[
                {
                  required: true,
                  message: "Please input telegramChatId!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Шлях до папки"
              name="pathToFolder"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Зберегти
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
