import { message } from "antd";
import { getServer } from "../../utils/Environment";

const server = getServer();

export const getClients = async () => {
  const ipsArr = await getIpsArr();
  const usersArrayBy10 = [];
  for (let index = 0; index < ipsArr.length; index++) {
    const ip = ipsArr[index];
    // if (ip === "91.229.76.95") {
    const users = await getUsersByIp(ip);
    usersArrayBy10.push(...getUsersArraysBy10(users));
    // }
  }
  return usersArrayBy10;
};

export const createClient = async (data) => {
  try {
    const result = await fetch(server + "/server/server.php", {
      method: "POST",
      body: JSON.stringify({ action: "createClient", data }),
    });
    return await result.json();
  } catch (error) {
    message.error(error);
    // throw error;
    // return false;
  }
};

export const getBackUps = async (users, sendNotification = false) => {
  const ids = users.map((user) => user.clientId);
  try {
    const result = await fetch(server + "/server/server.php", {
      method: "POST",
      body: JSON.stringify({ action: "getBackUpsData", ids, sendNotification }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    message.error(error);
    throw error;
    return false;
  }
};

export async function getIpsArr() {
  try {
    const result = await fetch(server + "/server/server.php", {
      method: "POST",
      body: JSON.stringify({ action: "getIpAddrs" }),
    });
    return await result.json();
  } catch (error) {
    console.log(error);
    throw error;
    // return false;
  }
}

export async function getUsersByIp(ip) {
  try {
    const result = await fetch(server + "/server/server.php", {
      method: "POST",
      body: JSON.stringify({ action: "getUsersByIp", ip }),
    });
    return await result.json();
  } catch (error) {
    console.log(error);
    throw error;
    // return false;
  }
}

export const setBackUpDataToClients = async (backUps, users) => {
  return await Promise.all(
    users.map(async (client) => {
      if (backUps == -3) {
        client.backUp = {
          error: "Помилка отримання даних про бекапи",
          mostRecent: "0",
          maxSize: 0,
          state: 0,
          id: client.clientId,
        };
      } else {
        const clientBackUp = backUps.find(
          (backUp) => backUp.id === client.clientId
        );
        if (clientBackUp) {
          client.backUp = clientBackUp;
        } else {
          client.backUp = {
            error: "Помилка",
            mostRecent: "Помилка",
            maxSize: 0,
            state: 0,
            id: client.clientId,
          };
        }
      }
      return client;
    })
  );
};

export function getUsersArraysBy10(users) {
  const resArray = [];
  if (users.length < 10) {
    resArray.push(users);
  } else {
    // const resArray = [];
    let subArray = [];
    const size = 10;
    for (let i = 0; i < Math.ceil(users.length / size); i++) {
      subArray[i] = users.slice(i * size, i * size + size);
    }
    resArray.push(...subArray);
  }
  return resArray;
}

export function convertToGB(bytesValue) {
  let gbValue = (bytesValue / (1000 * 1000 * 1000)).toFixed(2);
  return gbValue;
}

export const getCompanies = async () => {
  try {
    const result = await fetch(server + "/server/server.php", {
      method: "POST",
      body: JSON.stringify({ action: "getCompanies" }),
    });
    return await result.json();
  } catch (error) {
    console.log(error);
    message.error("Помилка отримання компаній!");
    throw error;
  }
};

export const getUsersByCompany = async (company) => {
  try {
    const result = await fetch(server + "/server/server.php", {
      method: "POST",
      body: JSON.stringify({ action: "getUsersByCompany", company }),
    });
    return await result.json();
  } catch (error) {
    console.log(error);
    message.error("Помилка отримання компаній!");
    throw error;
  }
};
