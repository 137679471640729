import React, { useEffect, useState } from "react";
import { Button, Table, Select, Space, Row, message } from "antd";
import {
  convertToGB,
  getCompanies,
  getUsersArraysBy10,
  getUsersByCompany,
} from "../../api/Clients/clients";
import {
  getBackUps,
  getClients,
  setBackUpDataToClients,
} from "../../api/Clients/clients";
import { Loading3QuartersOutlined, CloudSyncOutlined } from "@ant-design/icons";

export default function ClientsTable() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();

  useEffect(() => {
    // setClientsToTable();
    setCompaniesData();
  }, []);

  const setCompaniesData = async () => {
    const companies = await getCompanies();
    const companiesOptions = companies.map((company) => {
      return { value: company, label: company };
    });
    setCompanies(companiesOptions);
  };

  const getAllClients = async () => {
    const clientsArrays = await getClients();
    return clientsArrays.reduce((acc, cur) => acc.concat(cur), []);
  };

  // const setClientsToTable = async () => {
  //   setLoading(true);
  //   const allClients = await getAllClients();
  //   setClients(
  //     allClients.map((client) => {
  //       return { ...client, key: client.clientId };
  //     })
  //   );
  //   setLoading(false);
  // };

  const getAllClientsData = async () => {
    setSelectedCompany("Компанія");
    const allClients = await getAllClients();
    setClients(allClients);
    await getData([allClients]);
  };

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedUsers(selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getData = async (clientsToGet = clients) => {
    setLoading(true);
    // const arrBy10 = getUsersArraysBy10(...clientsToGet).slice(0, 1);
    // console.log(arrBy10);
    const res = await Promise.all(
      clientsToGet.map(async (arr, index) => {
        const backups = await getBackUps(arr, false);
        if (backups) return await setBackUpDataToClients(backups, arr);
      })
    );
    if (res) setClients(res.reduce((acc, cur) => acc.concat(cur), []));

    setLoading(false);
  };

  const columns = [
    {
      title: "№",
      dataIndex: "clientId",
      key: "clientId",
      width: "10%",
      sorter: (a, b) => a.clientId - b.clientId,
      defaultSortOrder: "descend",
    },
    {
      title: "Компанія",
      dataIndex: "companyName",
      key: "companyName",
      width: "20%",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      // defaultSortOrder: "descend",
    },
    {
      title: "Клієнт",
      dataIndex: "ClientName",
      key: "ClientName",
      width: "30%",
      sorter: (a, b) => a.ClientName.localeCompare(b.ClientName),
      // defaultSortOrder: "descend",
    },
    {
      title: "К-ть",
      dataIndex: "bkpCount",
      key: "bkpCount",
      width: "5%",
      sorter: (a, b) => a.bkpCount - b.bkpCount,
      // defaultSortOrder: "descend",
    },
    {
      title: "Дата останнього",
      dataIndex: "mostRecent",
      key: "mostRecent",
      width: "25%",
      sorter: (a, b) => a.mostRecent - b.mostRecent,
      // defaultSortOrder: "descend",
      render: (_, record) => {
        return record?.backUp?.mostRecent;
      },
    },
    {
      title: "Інфо",
      dataIndex: "error",
      key: "error",
      width: "25%",
      render: (_, record) => {
        return record?.backUp?.error;
      },
    },
    {
      title: "Розмір",
      dataIndex: "bkpSize",
      key: "bkpSize",
      width: "25%",
      sorter: (a, b) => {
        return a?.backUp?.maxSize - b?.backUp?.maxSize;
      },
      // defaultSortOrder: "descend",
      render: (_, record) => {
        return convertToGB(record?.backUp?.maxSize) + " GB";
      },
    },
  ];

  const onChange = async (value) => {
    setSelectedCompany(value);
    const users = await getUsersByCompany(value);

    setClients(
      users.map((user) => {
        return { ...user, key: user.clientId };
      })
    );
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const getBackUpsByCompany = async (company) => {
    if (!company && !selectedUsers.length)
      return message.error("Виберіть компанію або користувачів");
    if (selectedUsers.length > 0) {
      getData([selectedUsers]);
      return;
    }
    getData([clients]);
  };

  return (
    <>
      <Row>
        <Space direction="horizontal">
          <Button onClick={getAllClientsData}>
            <Loading3QuartersOutlined /> Оновити всі дані
          </Button>
          <Select
            value={selectedCompany}
            showSearch
            placeholder="Компанія"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={companies}
          />
        </Space>
        <Button
          onClick={async () => await getBackUpsByCompany(selectedCompany)}
        >
          <CloudSyncOutlined />
        </Button>
      </Row>
      <br></br>
      <Table
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          hideOnSinglePage: true,
        }}
        // onRow={onRowAction}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={clients}
        size={"middle"}
        tableLayout="fixed"
      />
    </>
  );
}
